import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "filter", "filtrable", "collection", "notice" ]

  apply() {
    this.collectionItems.forEach(item => {
      item.classList.remove("toggle-content")
    })

    if(this.filterValue != "") {
      this.collectionItems.filter(item =>
        item.getAttribute("data-collection-filters-key")!= this.filterValue
      ).forEach(item =>
        item.classList.add("toggle-content")
      )
    }

    if(this.visibleItems.length == 0) {
      this.displayWarning()
    } else {
      this.displayCollection()
    }
  }

  displayCollection() {
    this.collectionTarget.classList.remove("toggle-content")
    this.noticeTarget.classList.add("toggle-content")
  }

  displayWarning() {
    this.collectionTarget.classList.add("toggle-content")
    this.noticeTarget.classList.remove("toggle-content")
  }

  get collectionItems() {
    return Array.from(this.filtrableTargets)
  }

  get filterValue() {
    return this.filterTarget.value
  }

  get visibleItems() {
    return this.collectionItems.filter(el => !el.classList.contains("toggle-content"))
  }
}
