import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "template" ]

  connect() {
    this.addNew()
  }

  addNew() {
    this.element.insertAdjacentHTML("beforeend", this.templateTarget.innerHTML)
  }

  removeMe(event) {
    event.target.parentElement.remove()
  }
}
