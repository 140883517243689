import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "target" ]

  connect() {
    this.targetTarget.readOnly = true
  }

  setPosition(event) {
    this.targetTarget.value = this.nextPosition
  }

  get nextPosition(){
    return event.target.options[event.target.selectedIndex].getAttribute("data-next-position")
  }
}
