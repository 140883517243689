import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "username", "language", "button" ]

  locales = {
    it: { username: "nome utente",
          language: "lingua",
          button: "Accedi" },
    en: { username: "username",
          language: "language",
          button: "Login" }
  }

  change(event) {
    let selectedLocale = event.target.options[event.target.selectedIndex].value

    this.usernameTarget.innerHTML = this.locales[selectedLocale]["username"]
    this.languageTarget.innerHTML = this.locales[selectedLocale]["language"]
    this.buttonTarget.value = this.locales[selectedLocale]["button"]
  }
}
