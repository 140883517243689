import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "message", "icon" ]

  connect() {
    if(this.iconTarget.innerHTML = "favorite") {
      this.toggleClass()
    }
  }

  toggle() {
    this.toggleClass()

    if(this.messageTarget.innerHTML.trim() == this.allText) {
      this.messageTarget.innerHTML = this.yourText
        this.iconTarget.innerHTML = "favorite"
    } else {
      this.messageTarget.innerHTML = this.allText
      this.iconTarget.innerHTML = "favorite_border"
    }
  }

  toggleClass() {
    document.querySelectorAll(`#tbody_${this.visibleTab} .inert`).
             forEach(row => row.classList.toggle("toggle-content"))
  }

  get visibleTab() {
    return this.data.get("visible")
  }

  get allText() {
    return this.data.get("allText")
  }

  get yourText() {
    return this.data.get("yourText")
  }
}
