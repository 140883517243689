import Flatpickr   from "stimulus-flatpickr"
import { Italian } from "flatpickr/dist/l10n/it.js"
import { english } from "flatpickr/dist/l10n/default.js"

export default class extends Flatpickr {
  locales = {
    it: Italian,
    en: english
  }

  connect() {
    this.config = {
      locale: this.locale
    }
    super.connect()
  }

  open() {
    this.fp.open()
  }

  get locale() {
    if (this.locales && this.data.has("locale")) {
      return this.locales[this.data.get("locale")]
    } else {
      return "en"
    }
  }
}
