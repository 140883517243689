import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "trigger", "target" ]

  connect() {
    this.applyStyle()
  }

  applyStyle() {
    if (this.triggerTarget.value == "true") {
      this.targetTarget.style.display = "block"
    } else {
      this.targetTarget.querySelectorAll("input").
                        forEach(box => box.checked = false)
      this.targetTarget.style.display = "none"
    }
  }
}
