import { Controller } from "stimulus"
import Highcharts from 'highcharts'

export default class extends Controller {
  static targets = [ "container" ]

  chart = this.highChart

  connect() {
    this.chart
  }

  update(event) {
    if(!event.target.classList.contains("selected_link")) {
      this.element.querySelectorAll(".pointer").forEach(link => link.classList.remove("selected_link"))
      event.target.classList.add("selected_link")
      while (this.chart.series.length) {
        this.chart.series[0].remove()
      }
      for (let i=0; i < this.valuesSeries.length; i++) {
        let series = []
        this.valuesSeries[i].forEach(function(value, i) {
          series.push([i, value])
        })
        this.chart.addSeries({
	         name: this.nameSeries[i],
           data: series
				})
	     }
    }
  }

  get labelsEnabled() {
    return this.data.get("labels") == "true"
  }
  get yAxisTitle() {
    return this.data.get("y-title")
  }

  get nameSeries() {
    return JSON.parse(event.target.getAttribute("data-rpn-trend-chart-names"))
  }

  get valuesSeries() {
    return JSON.parse(event.target.getAttribute("data-rpn-trend-chart-values"))
  }

  get highChart() {
    if (this.hasContainerTarget) {
      return Highcharts.chart(this.containerTarget, {
                              credits: {
                                enabled: false
                              },
                              title: {
                                text: ''
                              },
                              legend: {
                                borderWidth: 0
                              },
                              xAxis: {
                                categories: JSON.parse(this.data.get("operations")),
                                labels:
                                {
                                  enabled: this.labelsEnabled
                                }
                              },
                              yAxis: {
                                title: {
                                  text: this.yAxisTitle
                                },
                                tickInterval: 15,
                                min: 0
                              },
                              tooltip: {
                                formatter: function() {
                                  return `${this.x}: <b>${parseFloat(this.y).toFixed(0)}</b>`
                                }
                              },
                              series: JSON.parse(this.data.get("points"))
                              }
                            )

    }
  }
}
