//  <select data-controller='remote-select-populators'
//          data-remote-select-populators-tag-id ='tag_id'
//          data-remote-select-populators-template-id ='template_id'
//          data-remote-select-populators-url='<%= model_path(format: :json)%>'
//          data-remote-select-populators-scope='OPTIONAL'
//          data-action='remote-select-populators#populate'>
//    ..
//  </select>
// <tag id='tag_id'>
//    ..
//  </tag>
//  <template id="template_id">
//    INTERPOLATED_VALUE
//  </template>

import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    if(this.selectedValue == "") {
      this.tagToPopulate.disabled = true
    }
  }

  populate(event) {
    this.clearTag()

    if(this.selectedValue != "") {
      fetch(`${this.remoteUrl}?${new URLSearchParams({ value: this.selectedValue,
                                                       scope: this.scopeToApply })}`)
      .then(response => response.json())
      .then(collection => {
        collection.forEach((item) => {
          let content = this.templateHTML
          Object.keys(item).forEach((key) => {
            let regExp = new RegExp(key.toUpperCase(), "g")
            content = content.replace(regExp, item[key])
          })
          this.tagToPopulate.insertAdjacentHTML("beforeend", content)
        })
      })

      this.tagToPopulate.disabled = false
    } else {
      this.tagToPopulate.disabled = true
    }
  }

  clearTag() {
    switch (this.tagToPopulate.tagName) {
  	case "SELECT":
      Array.from(this.tagToPopulate.options).
            filter(option => option.value != "").
            forEach(option => option.remove(0))
      break
    default:
      this.tagToPopulate.innerHTML = ""
    }
  }

  get selectedValue() {
    return this.element.options[this.element.selectedIndex].value
  }

  get remoteUrl() {
    return this.data.get("url")
  }

  get scopeToApply() {
    return this.data.get("scope")
  }

  get tagToPopulate() {
    return document.querySelector(`#${this.data.get("tag-id")}`)
  }

  get templateHTML() {
    return document.querySelector(`#${this.data.get("template-id")}`).innerHTML
  }
}
