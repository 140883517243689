// <div data-controller="typeaheads" data-typeaheads-url="URL">
//   <input class="text_field" type="text" data-typeaheads-target="textField"
//                                         data-action="input->typeaheads#displayOptions
// 																				             click@window->typeaheads#hideOptions">
// 	<div class="auto_complete">
// 	 <ul data-typeaheads-target="dropdown" style="display: none;"></ul>
// 	</div>
// 	<template data-typeaheads-target="template">
//	 <li data-action="click->typeaheads#setInputValue">OPTION</li>
//	</template>
// </div>

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "dropdown", "template", "textField", "remoteField" ]

  connect() {
    this.dropdownTarget.style.display = "none"
  }

  displayOptions(event) {
    if(this.input.length > 2) {
      this.dropdownTarget.innerHTML = ""

      fetch(`${this.remoteUrl}?${new URLSearchParams({ query: this.input, scope: this.urlScope })}`)
      .then(response => response.json())
      .then(collection => {
        collection.forEach((item) => {
          let content = this.templateHTML
          Object.keys(item).forEach((key) => {
            let regExp = new RegExp(key.toUpperCase(), "g")
            content = content.replace(regExp, item[key])
          });
          this.dropdownTarget.insertAdjacentHTML("beforeend", content)
        })

        if(collection.length > 0) {
          this.dropdownTarget.style.display = "block"
        }
      })
    } else {
      this.hideOptions()
    }
  }

  // Instead of clearing the remote, the remote should be removed
  // The backend should process the input value (form object)
  clearRemote(event) {
    if (this.hasRemoteFieldTarget) {
      let key = event.key
      if (key === "Backspace" || key === "Delete") {
        this.remoteFieldTarget.value = ""
      }
    }
  }

  hideOptions() {
    if (this.dropdownTarget.style.display == "block") {
      this.dropdownTarget.style.display = "none"
    }
  }

  setInputValue(event) {
    this.textFieldTarget.value = event.target.innerHTML
    // To be removed alonside clearRemote
    if (this.hasRemoteFieldTarget) {
      this.remoteFieldTarget.value = event.target.getAttribute("data-typeaheads-object-id")
    }
  }

  get input() {
    return event.target.value
  }

  get remoteUrl() {
    return this.data.get("url")
  }

  get templateHTML() {
    return this.templateTarget.innerHTML
  }

  get urlScope() {
    return this.data.get("scope")
  }
}
