import { Controller } from "stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
  timer = null

  start() {
    this.timer = setInterval(() => {
      this.save()
    }, 30000) //30 seconds
  }

  save() {
    if(this.element.value != "") {
      Rails.ajax({
        type: "POST",
        url:  "/save_quality_report_conclusions",
        data: `report_id=${document.querySelector("#report_id").value}&${this.element.name}=${this.element.value}`,
      })
    }
  }
}
