//  <div data-controller='partial'
//       data-partial-within='CSS SELECTOR',
//       data-partial-selected-option-value=true*,
//       data-partial-params="{\"object_id\": #{object.id}}"
//       data-partial-url='PATH'
//       data-partial-render-on-connect=true*,
//       data-action="partial#render">
//  </div>
//  <div CSS SELECTOR'>
//    ..
//  </div>
//
// * Optional

import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    if(this.renderOnConnect) {
      this.render()
    }
  }

  render() {
    if(this.selectedOptionValue) {
      this.data.set("url", this.remoteUrl.replace(RegExp(this.remoteUrl.split(/[/ ]+/).pop()+"$"), this.selectedValue))
    }
    dispatch_ajax_send()
    fetch(`${this.remoteUrl}?${new URLSearchParams(this.additionalParams)}`)
    .then(response => response.text())
    .then(html => { this.elementToInsertHTML.innerHTML = html })
    .then(_ => dispatch_ajax_complete())

    //if display: none
    if(this.elementToInsertHTML.getClientRects().length == 0) {
      this.elementToInsertHTML.style.display = "block"
    }

    if (this.elementToInsertHTML.hasAttribute("data-controller") &&
        this.elementToInsertHTML.getAttribute("data-controller").match(/partial/)) {
      this.elementToInsertHTML.dispatchEvent(new Event("change"))
    }
  }

  get remoteUrl() {
    return this.data.get("url")
  }

  get selectedOptionValue() {
    return this.data.get("selected-option-value") == 'true' ? true : false
  }

  get elementToInsertHTML() {
    return document.querySelector(this.data.get("within"))
  }

  get selectedValue() {
    return this.element.options[this.element.selectedIndex].value
  }

  get additionalParams() {
    return JSON.parse(this.data.get("params"))
  }

  get renderOnConnect() {
    return JSON.parse(this.data.get("render-on-connect"))
  }
}
