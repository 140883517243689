// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import "stylesheets/application.scss"

 require("@rails/ujs").start()
 require("turbolinks").start()
 require("@rails/activestorage").start()
// require("channels")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import "javascript/controllers"
import Rails from "@rails/ujs"


window.dispatch_ajax_send = function () {
  document.dispatchEvent(new Event('ajax:send'))
}

window.dispatch_ajax_complete = function () {
  document.dispatchEvent(new Event('ajax:complete'))
}

document.addEventListener("turbolinks:load", () => {
  document.addEventListener("ajax:send", () => {
    document.querySelector("#page_loader").classList.remove("hidden")
  })
  document.addEventListener("ajax:complete", () => {
    document.querySelector("#page_loader").classList.add("hidden")
  })
})

document.addEventListener("turbolinks:load", () => {
  const remoteLinks = Array.from(document.querySelectorAll("a[data-remote='true']"))
  remoteLinks.forEach(function(element) {
    element.dataset.url = element.href
    element.href = "javascript:void(0);"
  })
  Rails.href = function(element) {
    return element.dataset.url || element.href
  }
})
