import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "trigger", "target" ]

  connect() {
    this.applyStyle()
  }

  applyStyle() {
    if (this.triggerTarget.value == "true") {
      this.clientTargets.forEach(element => element.style.display = "block")
      this.noClientTargets.forEach(element => element.style.display = "none")
      this.noClientTargets.forEach(element => element.querySelectorAll("input:not([disabled])").
                                              forEach(box => box.checked = false))
    } else {
      this.clientTargets.forEach(element => element.style.display = "none")
      this.noClientTargets.forEach(element => element.style.display = "block")
    }
  }

  get clientTargets() {
    return this.targetTargets.filter(element => element.getAttribute("data-display") == "client")
  }

  get noClientTargets() {
    return this.targetTargets.filter(element => element.getAttribute("data-display") == "no-client")
  }
}
