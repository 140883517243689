//  <div data-controller='messages'>
//  </div>

import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    setTimeout(() => this.fadeOut(), 5000) //milliseconds
  }

  fadeOut() {
    this.element.addEventListener("animationend", () => {
      this.element.remove()
    })
    this.element.classList.remove("fadeInRight")
    this.element.classList.add("fadeOut")
  }
}
