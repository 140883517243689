//  <div data-controller='dynamic-text'
//       data-dynamic-text-select="SELECT ID"
//       data-dynamic-text-attribute="DATA ATTRIBUTE">
//    <div data-dynamic-text-target='element'>
//      <div data-dynamic-text-target='text'></div>
//    </div>
//  </div>

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "element", "template" ]

  connect() {
    this.updateText()
  }

  updateText() {
    if (this.completionDays != null) {
      this.elementTarget.style.display = "block"

      let content = this.templateHTML.replace("DAYS", this.completionDays)
      this.elementTarget.innerHTML = content

    } else {
      this.elementTarget.style.display = "none"
    }
  }

  get selectElement() {
    return document.querySelector(this.data.get("select"))
  }

  get selectedLevel() {
    return this.selectElement.options[this.selectElement.selectedIndex]
  }

  get completionDays() {
    return this.selectedLevel.getAttribute(`data-${this.selectDataAttribute}`)
  }

  get selectDataAttribute() {
    return this.data.get("attribute")
  }

  get templateHTML() {
    return this.templateTarget.innerHTML
  }
}
