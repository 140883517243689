import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "trigger", "fieldWrap" ]

  connect() {
    this.change()
  }

  change() {
    if (this.triggerTargets.filter(select => select.value == "true").length > 0) {
      this.fieldWrapTarget.style.display = "block"
    } else {
      this.fieldWrapTarget.style.display = "none"
    }
  }
}
