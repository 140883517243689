import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "sibling" ]

  connect() {
    this.dislaySibling()
  }

  change(event) {
    switch(event.target.tagName) {
      case 'SELECT':
        this.data.set("visible", event.target.options[event.target.selectedIndex].value)
        break;
      case 'A':
        this.data.set("visible", event.target.getAttribute("data-display-sibling-tab"))
        break;
    }
    this.dislaySibling()
  }

  dislaySibling() {
    this.siblingTargets.forEach(element => element.style.display = "none")
    this.siblingTargets.filter(select => select.getAttribute("data-parent") == this.visibleSibling).
                        map(select => select.style.display = "block")
  }

  get visibleSibling() {
    return this.data.get("visible")
  }
}
