import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    this.displayElement()
  }

  displayElement() {
    document.querySelector(this.elementToDisplay).style.display = "block"
    document.querySelector(this.elementToHide).style.display = "none"
  }

  get attributeName() {
    return this.data.get("attribute-name")
  }

  get selectedAttribute() {
    return this.element.options[this.element.selectedIndex].getAttribute(this.attributeName) == 'true' ? true : false
  }

  get elementToDisplay() {
    return this.data.get(`${this.selectedAttribute}-element-id`)
  }

  get elementToHide() {
    return this.data.get(`${!this.selectedAttribute}-element-id`)
  }
}
