import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "from", "to", "filtrable" ]

  apply() {
    if(this.fromDate > this.toDate) {
      alert("DATE ERROR")
    } else {
      this.collectionItems.forEach(item => {
        item.classList.remove("toggle-content")
        let itemDate = new Date(item.getAttribute("data-collection-date-filter-value"))
        if(itemDate < this.fromDate || itemDate > this.toDate) {
          item.classList.add("toggle-content")
        }
      })
     // The following code should be moved in a separate controller
     document.querySelector("#filter_from_date").value = this.fromTarget.value
     document.querySelector("#filter_to_date").value = this.toTarget.value
    }
  }

  get fromDate() {
    return new Date(this.fromTarget.value || '2008-01-01')
  }

  get toDate() {
    let now = new Date()
    return new Date(this.toTarget.value || `${now.getFullYear()}-${now.getMonth()+1}-${now.getDate()}`)
  }

  get collectionItems() {
   return Array.from(this.filtrableTargets)
 }
}
