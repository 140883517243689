import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [ "trigger" ]

  check(event) {
    if(this.conditionalElement == this.conditionalValue) {
      if (!window.confirm(this.confirmText)) {
        event.preventDefault()
      }
    }
  }

  get confirmText() {
    return this.data.get("confirm-text")
  }

  get conditionalElement() {
    return this.triggerTarget.options[this.triggerTarget.selectedIndex].value
  }

  get conditionalValue() {
    return this.data.get("condition")
  }
}
