import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "checkbox", "dateWrap" ]

  connect() {
    this.applyStyle()
  }

  applyStyle() {
    if(this.checkboxTarget.checked == true) {
      this.dateWrapTarget.querySelectorAll("input").
                          forEach(input => input.value = "")
      this.dateWrapTarget.style.display = "none"
      this.element.querySelectorAll(".invalid_field").
                          forEach(el => el.innerHTML = "")
    } else {
      this.dateWrapTarget.style.display = "block"
    }
  }
}
