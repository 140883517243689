// <table class="table-sortable" data-controller="table-sortable">
//  <thead>
//     <tr>
//      <th></th>
//      <th class="sortable" data-action="click->table-sortable#sort">header1</th>
//      <th class="sortable" data-action="click->table-sortable#sort">header2</th>
//    </tr>
//  </thead>
//  <tbody>
//    <tr>
//      <td data-sorting="VALUE">...</td>
//      ...
//    </tr>
//    <tr>...</tr>
//    <tr>...</tr>
//    <tr>...</tr>
//  </tbody>
// </table>

import { Controller } from "stimulus"

export default class extends Controller {
  sort(event) {
    let headerIndex = Array.prototype.indexOf.call(event.target.parentElement.children, event.target)
    let currentIsAscending = event.target.classList.contains("th-sort-asc")
    let dirModifier = currentIsAscending ? 1 : -1
    let tBody = this.element.querySelector("tbody")
    let rows = Array.from(tBody.querySelectorAll("tr"))

    // Sort each row
    let sortedRows = rows.sort((a, b) => {
      let first = a.querySelector(`td:nth-child(${ headerIndex + 1 })`)
      let second = b.querySelector(`td:nth-child(${ headerIndex + 1 })`)
      let aColValue = first.getAttribute("data-sorting") || first.textContent.trim()
      let bColValue = second.getAttribute("data-sorting") || second.textContent.trim()

      return aColValue > bColValue ? (1 * dirModifier) : (-1 * dirModifier)
    })

    // Remove all existing TRs from the table
    while (tBody.firstChild) {
      tBody.removeChild(tBody.firstChild)
    }

    // Re-add the newly sorted rows
    tBody.append(...sortedRows)

    // Display how the column is currently sorted
    this.element.querySelectorAll("th").forEach(th => th.classList.remove("th-sort-asc", "th-sort-desc"))
    event.target.classList.toggle("th-sort-asc", !currentIsAscending)
    event.target.classList.toggle("th-sort-desc", currentIsAscending)
  }
}
